<template>
  <div class="item-root" style="cursor: pointer;"
       @click="$emit('resultSelected', result)"
       @mouseleave="$emit('resultMouseLeave', result)"
       @mouseover="$emit('resultMouseOver', result)">
    <div class="w-100 h-100 d-inline-flex text-left align-items-center">
      <img :src="category2icon(result.category)"
           style="height: 24px; width: 24px; margin-right: 16px; opacity: 0.25">
      <div class="result-title">
        {{ result.name }}
      </div>
      <!--      <img class="icon-more-info ml-auto" src="@/assets/icon/icon_more_info.svg"
                 style="height: 48px; width: 48px; padding: 12px; border-radius: 24px; border: solid 1px #BEDCD4;">-->
    </div>
    <div class="divider"/>
  </div>
</template>

<script>
import category2icon from '@/utils/category2icon'

export default {
  name: 'SearchResult',
  props: ['result'],
  methods: {
    category2icon (category) {
      return category2icon(category)
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/styles";

.item-root {
  height: 80px;
  padding-bottom: 0;
}

.item-root:hover {
  background-color: rgba(190, 220, 212, 0.15);
}

.result-title {
  font-weight: 500;
  font-size: 16px;
  margin-right: 16px;
}

.divider {
  opacity: 0.5;
  border-bottom: 1px solid @color-grey;
}
/*.icon-more-info:hover {
  background: #BEDCD4;
}*/

</style>
