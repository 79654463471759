<template>
  <div class="item-root text-left">

    <div v-if="error" class="text-center">
      <img src="/icon/error_0.svg" style="max-width: 300px; max-height: 300px; margin-bottom: 42px;">
      <div style="padding-left: 10%; padding-right: 10%;">
        <h4 style="margin-bottom: 12px;">{{ $t('error_we_are_sorry') }}</h4>
        <div>
          {{ $t('error_system') }}
        </div>
      </div>
    </div>

    <div v-else>
      <div class="w-100 d-inline-flex" style="margin-bottom: 24px;">
        <div class="text-left w-auto mr-2">
          <div class="result-title" v-text="detail ? detail.place.name : place.name"></div>
          <div v-if="detail && detail.place">
            {{ Object.values(detail.place.full_name).join(', ') }}
          </div>
          <div v-else-if="place && place.full_name">
            {{ Object.values(place.full_name).join(', ') }}
          </div>
        </div>
        <div class="mb-auto ml-auto mr-2 mt-2 text-center">
          <img id="favourite" :class="detail ? 'icon-favourite' : 'icon-favourite icon-favourite-disabled'"
               :src="isFavourite ? '/icon/icon_favourite_full.svg' : '/icon/icon_favourite.svg'"
               @click="toggleFavourite">
          <b-tooltip target="favourite">
            <div class="favourite-tooltip">
              <div style="font-weight: 500; font-size: 16px; line-height: 30px;">
                {{ isFavourite ? $t('tooltip_favourite_title_remove') : $t('tooltip_favourite_title_add') }}
              </div>
              {{ $t('tooltip_favourite_text') }}
            </div>
          </b-tooltip>
        </div>
      </div>

      <div v-if="detail">
        <div class="d-inline-flex">
          <b-icon-clock class="icon-clock"/>
          <div v-if="detail.opening_times && !detail.raw_time" class="">
             <div class="d-inline-flex">
               <h5 style="font-weight: 500; line-height: 24px; margin-right: 16px;">
              {{ $t('mo') }}<br>
              {{ $t('tu') }}<br>
              {{ $t('we') }}<br>
              {{ $t('th') }}<br>
              {{ $t('fr') }}<br>
              {{ $t('sa') }}<br>
              {{ $t('su') }}<br>
            </h5>
            <h5 class="" style="font-weight: 500; line-height: 24px;">
              <div v-if="detail.opening_times.mo">
                {{ this.mins2Time(detail.opening_times.mo[0]) }} - {{ this.mins2Time(detail.opening_times.mo[1]) }}
              </div>
              <div v-else>{{ $t("basic_closed") }}</div>
              <div v-if="detail.opening_times.tu">
                {{ this.mins2Time(detail.opening_times.tu[0]) }} - {{ this.mins2Time(detail.opening_times.tu[1]) }}
              </div>
              <div v-else>{{ $t("basic_closed") }}</div>
              <div v-if="detail.opening_times.we">
                {{ this.mins2Time(detail.opening_times.we[0]) }} - {{ this.mins2Time(detail.opening_times.we[1]) }}
              </div>
              <div v-else>{{ $t("basic_closed") }}</div>
              <div v-if="detail.opening_times.th">
                {{ this.mins2Time(detail.opening_times.th[0]) }} - {{ this.mins2Time(detail.opening_times.th[1]) }}
              </div>
              <div v-else>{{ $t("basic_closed") }}</div>
              <div v-if="detail.opening_times.fr">
                {{ this.mins2Time(detail.opening_times.fr[0]) }} - {{ this.mins2Time(detail.opening_times.fr[1]) }}
              </div>
              <div v-else>{{ $t("basic_closed") }}</div>
              <div v-if="detail.opening_times.sa">
                {{ this.mins2Time(detail.opening_times.sa[0]) }} - {{ this.mins2Time(detail.opening_times.sa[1]) }}
              </div>
              <div v-else>{{ $t("basic_closed") }}</div>
              <div v-if="detail.opening_times.su">
                {{ this.mins2Time(detail.opening_times.su[0]) }} - {{ this.mins2Time(detail.opening_times.su[1]) }}
              </div>
              <div v-else>{{ $t("basic_closed") }}</div>
            </h5>
             </div>


              <div>
                <h5 style="font-weight: 500; line-height: 24px;">
                  {{ $t("basic_text_opening_hours") }}
                </h5>
                <div class="change-hours text-center">
                  <a href="/instructions" target="_blank">
                    {{ $t('basic_change_opening_hours') }}
                  </a>
                </div>
              </div>


          </div>
          <div v-else-if="detail.raw_time && detail.opening_times">
            <h5 style="font-weight: 500; line-height: 24px;">
              {{ detail.opening_times }}</h5>
            <h5 style="font-weight: 500; line-height: 24px;">{{ $t('basic_wrong_hours') }}</h5>
            <div class="add-hours text-center" style="width: 50%">
              <a href="/instructions" target="_blank" style="font-weight: 500; line-height: 24px; font-size: 13px;">
                {{ $t('basic_opening_hours') }}
              </a>
            </div>
          </div>
          <div v-else>
            <h5 style="font-weight: 500; line-height: 24px;">{{ $t('error_opening_times_not_available') }}</h5>
            <div class="add-hours text-center">
              <a href="/instructions" target="_blank" style="font-weight: 500; line-height: 24px; font-size: 13px;">
                {{ $t('basic_opening_hours') }}
              </a>
            </div>
          </div>
        </div>

        <div class="divider"/>

        <h5 style="font-weight: 500; line-height: 24px; margin-top: 24px">
          {{ $t("basic_graph_text") }}
        </h5>

        <div class="row ml-0 mb-2">
          <div v-for="(n, i) in 5" v-bind:key="i"
               :class="selectedDay === i ? 'daytab daytab-active' : 'daytab'"
               @click="selectedDay = i"
               v-text="i === 0 ? $t('basic_today') : i === 1 ? $t('basic_tomorrow') : dayStrings[`${detail.model[i].day}`]">
          </div>
        </div>

        <AreaChart style="max-height: 300px;" v-bind:chart-data="chartData" v-bind:day-index="selectedDay"
                   v-bind:opening-times="openingTimes"/>

        <div class="green-box">
          <div class="d-inline-flex">
            <img src="/icon/science-fiction-robot.svg" style="width: 20px; height: 20px; margin-right: 15px;"/>
            <div style="font-weight: 600;">
              {{ $t('detail_wrong_chart_title') }}
            </div>
          </div>
          <h5 style="font-weight: 500;">
            {{ $t('detail_wrong_chart_text') }}
          </h5>
          <a class="box-btn" href="https://play.google.com/store/apps/details?id=com.chmelva4.fremenexplorer">
            <img class="store-icon" src="/icon/google_play.svg" style="width: 135px; height: 40px;"/>
          </a>
          <a class="box-btn" href="https://apps.apple.com/us/app/fremen-explorer/id1562737610">
            <img class="store-icon" src="/icon/apple_store.svg" style="width: 135px; height: 40px;"/>
          </a>
        </div>
      </div>

      <div v-else class="text-center">
        <lottie-animation :autoPlay="true"
                          :loop="true"
                          :speed="1"
                          path="animation/loading_map.json"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import config from '@/config'
import AreaChart from '@/components/map/AreaChart'

export default {
  name: 'Detail',
  props: ['place'],
  components: {
    AreaChart
  },
  data: function () {
    return {
      detail: null,
      selectedDay: 0,
      isFavourite: false,
      error: null,
      dayStrings: {
        'mo': this.$t('mo'),
        'tu': this.$t('tu'),
        'we': this.$t('we'),
        'th': this.$t('th'),
        'fr': this.$t('fr'),
        'sa': this.$t('sa'),
        'su': this.$t('su')
      }
    }
  },
  mounted () {
    this.config = config
    this.initFavourite()
  },
  created () {
    // Canceling request when Detail is destroyed
    this.cancelTokenSource = axios.CancelToken.source()
    this.cancelToken = this.cancelTokenSource.token
    if (this.place) {
      this.fetchDetail(true)
    }
  },
  destroyed () {
    this.cancelTokenSource.cancel('Detail is destroyed')
  },
  computed: {
    chartData: function () {
      if (this.detail && this.detail.model) {
        return this.detail.model
      }
      return null
    },
    openingTimes: function () {
      if (this.detail && this.detail.model && !this.detail.raw_time && this.detail.opening_times) {
        let times = this.detail.opening_times[`${this.detail.model[this.selectedDay].day}`]
        if (times) {
          return times
        } else {
          return []
        }
      } else {
        return null
      }
    }
  },
  watch: {
    place: function (place) {
      this.detail = null
      if (place) {
        this.initFavourite()
        this.fetchDetail(true)
      }
    }
  },
  methods: {
    initFavourite () {
      try {
        const favourites = this.$storage.get('favourites', [])
        for (const fav of favourites) {
          if (fav &&
              fav.osm_id && this.place.osm_id && fav.osm_id === this.place.osm_id &&
              fav.osm_type && this.place.osm_type && fav.osm_type === this.place.osm_type) {
            this.isFavourite = true
            return
          }
        }
        this.isFavourite = false
      } catch (e) {
        console.log(`[storage] Error obtaining favourites from storage: ${e.toString()}`)
      }
    },
    /***
     * TBD
     * @param requireModel
     */
    fetchDetail (requireModel) {
      this.error = null

      const url = `${config.baseUrlAPI}/api/detail`
      axios.post(url, {
        place: this.place,
        model: `${requireModel}`
      }, {
        cancelToken: this.cancelToken
      }).then(response => {
        this.detail = response.data
      }).catch(e => {
        if (axios.isCancel(e)) {
          console.log('/api/detail request canceled successfully')
        } else {
          this.error = e
          console.log(`[Detail/fetchDetail] Error loading data: ${e.toString()}`)
        }
      })
    },
    toggleFavourite () {
      if (!this.detail) return
      try {
        let favourites = this.$storage.get('favourites', [])
        if (this.isFavourite) {
          this.removeFavourite(favourites, this.detail.place)
        } else {
          this.addFavourite(favourites, this.detail.place)
        }
        this.isFavourite = !this.isFavourite
      } catch (e) {
        console.log(`[storage] Error toggle favourites: ${e.toString()}`)
      }
    },
    addFavourite (favourites, place) {
      try {
        if (favourites.length >= 3) {
          favourites = [favourites[1], favourites[2], place]
        } else {
          favourites.push(place)
        }
        this.$storage.set('favourites', favourites)
      } catch (e) {
        console.log(`[storage] Error saving favourites: ${e.toString()}`)
      }
    },
    removeFavourite (favourites, place) {
      for (let i = 0; i < favourites.length; i++) {
        if (this.equalPlace(favourites[i], place)) {
          try {
            favourites.splice(i, 1)
            this.$storage.set('favourites', favourites)
          } catch (e) {
            console.log(`[storage] Error removing favourites: ${e.toString()}`)
          }
        }
      }
    },
    isInFavourites (favourites, place) {
      for (const fav of favourites) {
        if (this.equalPlace(fav, place)) {
          return true
        }
      }
      return false
    },
    equalPlace (place1, place2) {
      return place1 && place2 &&
          place1.osm_id && place2.osm_id && place1.osm_id === place2.osm_id &&
          place1.osm_type && place2.osm_type && place1.osm_type === place2.osm_type
    },
    mins2Time (mins) {
      return `${this.pad(Math.floor(mins / 60), 2)}:${this.pad(Math.floor(mins % 60), 2)}`
    },
    pad (n, digits) {
      return ('0' + n).substr(-digits)
    }
  }
}
</script>

<style lang="less" scoped>
@import "../../assets/css/styles";

.favourite-tooltip {
  color: @color-blue-dark !important;
}

.item-root {
  padding: 12px 30px 30px 30px;
}

.divider {
  opacity: 0.5;
  border-bottom: 1px solid @color-grey;
}

.result-title {
  font-weight: 600;
  margin-bottom: 6px;
}

.icon-favourite {
  height: 48px;
  width: 48px;
  padding: 10px;
  border-radius: 24px;
  border: solid 1px @color-green;
  opacity: 1;
  cursor: pointer;
}

.icon-favourite-disabled {
  opacity: 0.4;
  cursor: default;
  pointer-events: none;
}

.icon-favourite:hover {
  background: @color-green;
}

.icon-clock {
  color: @color-green;
  width: 24px;
  height: 24px;
  font-size: 20px;
  margin-right: 8px;
}

.daytab {
  padding: 4px 4px;
  opacity: 0.5;
  font-weight: 500;
  font-size: 14px;
  box-shadow: none;
  border: none;
  outline: none;
  margin-right: 12px;
  background: @color-white;
  color: @color-blue-dark;
  border-radius: 0;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  transition-duration: 0.1s;
}

.daytab:hover {
  opacity: 0.9;
}

.daytab-active {
  background: @color-white;
  opacity: 1;
  border-bottom: 2px solid @color-green;
}

.green-box {
  background: @color-green;
  border-radius: 10px;
  padding: 18px;
  margin-top: 40px;
}

.store-icon {
  margin-top: 11px;
}

.add-hours  {
  background: @color-green;
  border-radius: 18px;
  padding: 2px;
  margin: 5px 15px 15px 5px;
}

.add-hours a {
  color: #0C243D;
}

.change-hours {
  background: @color-green;
  border-radius: 18px;
  padding: 2px;
  margin: 5px 15px 15px 5px;
  font-weight: 450;
  width: 50%;
  font-size: 14px;
}

.change-hours a {
  color: #0C243D;
}

</style>


