<template>
  <b-container class="vh-100 vw-100" fluid="" style="height: calc(100vh);">
    <b-row>

      <!-- Results VIEW -->
      <b-col v-show="!showMap || $screen.width > 992" class="pl-0 pr-0 text-left col-results" cols="0" lg="3"
             style="border-right: 1px solid rgba(200,200,200,0.2);">
        <b-navbar class="navbar-h pt-0 pb-0 p-result-panel" style="margin-bottom: 12px;" toggleable="lg">
          <b-navbar-brand class="pt-0 pb-0">
            <b-img class="logo navbar-h" src="logo.svg" style="cursor: pointer;" @click="$router.push('/')"></b-img>
          </b-navbar-brand>
        </b-navbar>


        <div v-if="detail && detail.category !== 'other'" class="detail-container">
          <button class="m-result-panel pl-0 back-btn" @click="detail = null">
            <b-icon class="mr-2" icon="arrow-left"/>
            {{ $t('basic_back_to_list') }}
          </button>

          <Detail class="detail" v-bind:place="detail"/>
        </div>


        <div v-else>
          <button class="m-result-panel pl-0 back-btn" @click="hasHistory()
                  ? $router.go(-1)
                  : $router.push('/')">
            <b-icon class="mr-2" icon="arrow-left"/>
            {{ $t('basic_back') }}
          </button>

          <autocomplete
              ref="autocomp"
              :aria-label="$t('search_hint')"
              :debounceTime="200"
              :default-value="searchString"
              :get-result-value="getResultValue"
              :placeholder="$t('search_hint')"
              :search="search"
              base-class="autocomp-map"
              class="m-result-panel input-h"
              style="margin-bottom: 12px; "
              @submit="onSubmit"
          >
            <template
                #default="{
                  rootProps,
                  inputProps,
                  inputListeners,
                  resultListProps,
                  resultListListeners,
                  results,
                  resultProps
                }"
            >
              <div v-bind="rootProps">
                <div class="align-items-center w-100" style="display: grid;">
                  <input
                      v-bind="inputProps"
                      v-on="inputListeners"
                      style="padding-right: 60px; grid-row: 1; grid-column: 1;"
                  />
                  <img v-show="searchString && searchString.length > 0"
                       src="/icon/cross.svg"
                       style="width: 20px; height: 20px; position: relative; left: calc(100% - 32px); cursor: pointer; grid-row: 1; grid-column: 1;"
                       @click="emptySearchStringAndFetch">
                </div>

                <ul v-bind="resultListProps" v-on="resultListListeners">
                  <li
                      v-for="(result, index) in results"
                      :key="resultProps[index].id"
                      v-bind="resultProps[index]"
                  >
                    <div class="d-inline-flex text-left align-items-center">
                      <img :src="category2icon(result.category)"
                           style="width: 24px; height: 24px; opacity: 0.25; margin-right: 12px;">
                      {{ getResultValue(result) }}
                    </div>
                  </li>
                </ul>
              </div>
            </template>
          </autocomplete>

          <h5 class="p-result-panel" style="font-weight: 600" v-html="$t('basic_select_category')"></h5>

          <div class="p-result-panel">
            <b-row class="category-r" cols="3">
              <b-col class="category-c1">
                <category-button :is-selected="selectedType === 'markets'" category="markets"
                                 v-on:onSelect="(c) => this.selectedType = c"></category-button>
              </b-col>
              <b-col class="category-c2">
                <category-button :is-selected="selectedType === 'food'" category="food"
                                 v-on:onSelect="(c) => this.selectedType = c"></category-button>
              </b-col>
              <b-col class="category-c3">
                <category-button :is-selected="selectedType === 'pharmacy'" category="pharmacy"
                                 v-on:onSelect="(c) => this.selectedType = c"></category-button>
              </b-col>
            </b-row>
            <b-row class="category-r" cols="3">
              <b-col class="category-c1">
                <category-button :is-selected="selectedType === 'park'" category="park"
                                 v-on:onSelect="(c) => this.selectedType = c"></category-button>
              </b-col>
              <b-col class="category-c2">
                <category-button :is-selected="selectedType === 'office'" category="office"
                                 v-on:onSelect="(c) => this.selectedType = c"></category-button>
              </b-col>
            </b-row>

          </div>

          <!--<div class="p-result-panel h5" style="margin-bottom: 12px; height: 22px">
            <h5>Seřadit podle:
              <span style="font-weight: 600;">
            <b-form-select id="formSortType"
                           :options="[{value: 'occupancy', text: 'VYTÍŽENOSTI'}, {value: 'distance', text: 'VZDÁLENOSTI'}]"
                           class="w-auto"
                           style="border: none; box-shadow: none; font-weight: 600; font-size: 14px"
                           value="occupancy"
                           @change="$emit('onSortTypeSelected', 'utas')">
          </b-form-select>
        </span>
            </h5>
          </div>-->

          <div class="divider"/>

          <!-- Height needs to be manually recomputed if something is changed (for example +50px for sort selection)-->
          <div class="result-container text-center">

            <div v-if="loadingData || mapLoading">
              <!-- Loading placeholder -->
              <div v-for="n in 5" v-bind:key="n" class="p-result-panel">
                <div class="w-100 d-inline-flex text-left align-items-center" style="height: 80px;">
                  <div class="loading-placeholder-category"/>
                  <div class="loading-placeholder-text"/>
                </div>
                <div class="divider"/>
              </div>
            </div>

            <div v-else-if="error" class="text-center">
              <!-- System error -->
              <img class="error-img" src="/icon/error_0.svg" style="margin-bottom: 42px;">
              <div style="padding-left: 10%; padding-right: 10%;">
                <h4 style="margin-bottom: 12px;">{{ $t('error_we_are_sorry') }}</h4>
                <div>
                  {{ $t('error_system') }}
                </div>
              </div>
            </div>

            <div v-else-if="filteredPlaces && filteredPlaces.length > 0">
              <!-- Valid results -->
              <SearchResult
                  v-for="place in filteredPlaces" v-bind:key="place.id" class="p-result-panel" v-bind:result="place"
                  v-on:resultMouseLeave="hidePopup"
                  v-on:resultMouseOver="res => showPopup([res.location.lon, res.location.lat], res.full_name)"
                  v-on:resultSelected="selectDetail"/>
            </div>

            <div v-else class="h-100" style="padding: 20px;">
              <!-- No results found -->
              <img class="error-img" src="icon/no_results.svg"/>
              <h3>{{ $t('error_we_are_sorry_2') }}
              </h3>
              {{ $t('error_no_results') }}
              <div class="add-new-place text-center"> 
                <a href="/instructions" target="_blank" style="font-weight: 500; line-height: 30px; font-size: 16px;">
                  {{ $t('basic_goto_osm') }}
                </a>
              </div>
            </div>
          </div>

          <div v-show="$screen.width <= 992" class="w-100 switcher-btn"
               @click="showMap = !showMap">
            <div class="switcher-btn-text" v-text="showMap ? $t('switcher_go_to_list') : $t('switcher_go_to_map')"/>
            <img class="ml-auto switcher-btn-icon" src="/icon/icon_arrow_forward.svg">
          </div>
        </div>
      </b-col>


      <b-col v-show="showMap || $screen.width > 992" class="pl-0 pr-0 map-h" cols="0" lg="9">
        <div style="display: grid;">
          <mapbox
              :map-options="{style: 'mapbox://styles/kdynakoupit/ckhyt2cv624h919o551i2m67x', center: [14.443305, 50.073243], zoom: 12}"
              access-token="pk.eyJ1Ijoia2R5bmFrb3VwaXQiLCJhIjoiY2toeXN1bXduMDE0ODJ6cW9nemg4czZ4cSJ9.9euZgzxdKo_o62w-mvQ6-w"
              class="map-h w-100"
              style="grid-row: 1; grid-column: 1;"
              @map-load="loaded"
              @geolocate-error="geolocateError"
              @geolocate-geolocate="geolocate"
              @map-zoomend="zoomend"
              @map-dragend="dragEnd"
              @map-click:results="clicked"
              @map-mouseenter:results="mouseEnterFeature"
              @map-mouseleave:results="mouseLeaveFeature"
          />
          <div v-if="validSearchString(searchString)"
               class="zoomout-btn d-inline-flex justify-content-center"
               style="grid-row: 1; grid-column: 1;"
               @click="mapZoomToCzechRepublic">
            <img class="mt-auto mb-auto" src="/icon/category_other.svg" style="height: 24px; margin-right: 12px;">
            <h5 class="mt-auto mb-auto" style="font-weight: 500" v-html="$t('map_search_whole_state')"></h5>
          </div>
        </div>

        <div v-show="$screen.width <= 992" class="w-100 switcher-btn"
             @click="showMap = !showMap">
          <div class="switcher-btn-text" v-text="showMap ? $t('switcher_go_to_list') : $t('switcher_go_to_map')"/>
          <img class="ml-auto switcher-btn-icon" src="/icon/icon_arrow_forward.svg">
        </div>
        <vue-element-loading :active="this.mapLoading" color="@color-blue-dark" spinner="bar-fade-scale"/>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import CategoryButton from '@/components/map/CategoryButton'

window.mapboxgl = require('mapbox-gl')

import Mapbox from 'mapbox-gl-vue'
import mapboxgl from 'mapbox-gl'
import axios from 'axios'
import SearchResult from '../components/map/SearchResult'
import Detail from '../components/map/Detail'
import VueElementLoading from 'vue-element-loading'
import config from '../config'
import * as turf from '@turf/turf'
import Autocomplete from '@trevoreyre/autocomplete-vue'
import category2icon from '@/utils/category2icon'
import '../assets/css/searchbar-map.less'

export default {
  name: 'MapPage',
  components: {
    CategoryButton,
    Mapbox,
    SearchResult,
    Detail,
    Autocomplete,
    VueElementLoading
  },
  created () {
    // Variables used in this component, but not reactive
    this.map = null
    this.zoomOnResults = false
    this.searchResults = null
    this.mapPopup = new mapboxgl.Popup({
      closeButton: false,
      closeOnClick: false,
      offset: [0, -10]
    })
    // Used when request are being stacked. For example during extensive moving on the map.
    this.cancelTokenSource = axios.CancelToken.source()
    this.cancelToken = this.cancelTokenSource.token

    let searchString = this.$route.query.ss
    if (searchString) {
      searchString = decodeURI(searchString)
      this.searchString = searchString
    }

    let searchType = this.$route.query.st
    let category = this.$route.query.type
    if (searchType) {
      searchType = decodeURI(searchType)
      if (searchType === 'other') {
        if(category){
          this.selectedType = category
        } else {
         this.selectedType = 'markets'
        }
      } else if (searchType === 'supermarket' || searchType === 'shop') {
        this.selectedType = 'markets'
      } else if (searchType === 'restaurant' || searchType === 'fastfood') {
        this.selectedType = 'food'
      } else {
        this.selectedType = searchType
      }
    }

    let osmId = this.$route.query.i
    let osmType = this.$route.query.t
    let lat = null
    let lon = null
    try {
      if (this.$route.query.lat && this.$route.query.lon) {
        lat = parseFloat(this.$route.query.lat)
        lon = parseFloat(this.$route.query.lon)
      }
    } catch (e) {
      console.error('[MapPage/created] Error parsing lat/lon to float')
    }

    if (osmId && osmType && lat && lon) {
      this.detail = {
        osm_id: `${osmId}`,
        osm_type: `${osmType}`,
        category: `${searchType}`,
        name: `${searchString}`,
        location: {
          lat: lat,
          lon: lon
        }
      }
    } else if (lat && lon) {
      this.initMapPosition = [lon, lat]
    }
    this.updateQuery()
  },
  data () {
    return {
      response: null,
      searchInit: '',
      typeOptions: [
        {
          value: 'markets',
          text: this.$t('category_markets')
        },
        {
          value: 'food',
          text: this.$t('category_food')
        },
        {
          value: 'pharmacy',
          text: this.$t('category_pharmacy')
        },
        {
          value: 'park',
          text: this.$t('category_park')
        },
        {
          value: 'office',
          text: this.$t('category_office')
        },
        {
          value: 'all',
          text: this.$t('category_all')
        }
      ],
      searchString: '',
      selectedType: 'markets',
      bbox: null,
      detail: null,
      showMap: false,
      loadingData: false,
      error: null,
      mapLoading: true,
      userLocation: null
    }
  },
  computed: {
    places: function () {
      if (this.response && this.response.places) {
        return this.response.places
      } else {
        return []
      }
    },
    filteredPlaces: function () {
      if (this.places) {
        return this.places.filter((place) => place.category !== 'other')
      }
      return []
    },
    geoData () {
      if (this.response && this.response.geojson) {
        return this.response.geojson
      } else {
        return null
      }
    },
    searchBbox () {
      if (this.response && this.response.bbox) {
        const b = this.response.bbox
        return {
          'type': 'FeatureCollection',
          'features': [
            {
              'type': 'Feature',
              'geometry': {
                'type': 'Polygon',
                'coordinates': [
                  [
                    [b[1], b[0]], [b[3], b[0]], [b[3], b[2]], [b[1], b[2]], [b[1], b[0]]
                  ]
                ]
              },
              'properties': {
                'prop0': 'value0',
                'prop1': { 'this': 'that' }
              }
            }
          ]
        }
      } else {
        return null
      }
    }
  },
  watch: {
    geoData: function () {
      this.updateMap()
    },
    selectedType: function (type) {
      this.fetchData(this.searchString, type, this.mapBounds())
    },
    showMap: function (show) {
      /* On mobile, map would stay small if resize is not called.
      Some small delay is necessary because map must be rendered
      */
      if (show) {
        setTimeout(() => {
          this.map.resize()
        }, 50)
      }
    }
  },
  methods: {
    loaded (map) {
      this.map = map
      map.addSource('source-results', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': []
        }
      })
      map.addSource('source-bbox', {
        type: 'geojson',
        data: {
          'type': 'FeatureCollection',
          'features': []
        }
      })
      map.addLayer({
        id: 'bbox',
        source: 'source-bbox',
        type: 'fill',
        paint: {
          'fill-color': '#BEDCD4',
          'fill-outline-color': '#BEDCD4',
          'fill-opacity': 0.3
        }
      })
      map.addLayer({
        id: 'results',
        type: 'symbol',
        source: 'source-results',
        layout: {
          'icon-image': 'icon-{icon}',
          'icon-allow-overlap': true,
          /*'text-allow-overlap': true,*/
          'text-field': '{name}',
          'text-font': ['Work Sans Medium'],
          'text-offset': [0, 1.2],
          'text-anchor': 'top',
          'text-optional': true
        },
        paint: {
          'icon-color': '#0C243D',
          'text-color': '#0C243D'
        },
        'filter': ['!=', 'category', 'other']
      })
      this.updateMap()
      if (this.detail) {
        this.selectDetail(this.detail)
      } else if (this.initMapPosition) {
        this.map.flyTo({
          center: this.initMapPosition,
          duration: 0
        })
      } else {
        this.fetchData(this.searchString, this.selectedType, this.mapBounds())
      }
      this.mapLoading = false
      /*this.$getLocation({
        enableHighAccuracy: true, //defaults to false
        timeout: Infinity, //defaults to Infinity
      }).then(coordinates => {
        /!** Example of coordinates object
         {
             "lat": 48.8112128,
             "lng": 14.322892800000002,
             "altitude": null,
             "altitudeAccuracy": null,
             "accuracy": 28857
           }
         *!/
        this.userLocation = coordinates
        let options = {
          center: [coordinates.lng, coordinates.lat],
          duration: 1000
        }
        if (this.map) {
          if (this.map.getZoom() < 15) {
            options.zoom = 15
          }
          this.map.flyTo(options)
        }
      }).catch(e => {
        console.log("[MapPage/Geolocation] " + e)
      })*/
    },
    fetchData (searchString, searchType, latlngBounds) {
      if (this.loadingData) {
        this.cancelTokenSource.cancel('Trying to fire another request, previous one canceled.')
        this.cancelTokenSource = axios.CancelToken.source()
        this.cancelToken = this.cancelTokenSource.token
      }
      // For now, we only show loading when searching with no string, since it is slow.
      // this.loadingData = true;

      const url = `${config.baseUrlAPI}/api/search`
      let data = {
        category: `${searchType}`,
        geojson: true,
        max_results: 50
      }
      if (this.validSearchString(searchString)) {
        data.search_string = `${searchString}`
      } else {
        // For now, we only show loading when searching with no string, since it is slow.
        this.loadingData = true
      }
      if (latlngBounds) {
        data.bbox = latlngBounds
      }
      this.error = null
      axios.post(url, data, {
        cancelToken: this.cancelToken
      }).then(response => {
        this.loadingData = false
        this.response = response.data
      }).catch(e => {
        if (!axios.isCancel(e)) {
          this.loadingData = false
          this.error = e
          console.log(`[MapPage/fetchData] Error: ${e.toString()}`)
        }
      })
    },
    updateMap () {
      if (this.map && this.geoData) {
        const source = this.map.getSource('source-results')
        if (source) {
          source.setData(this.geoData)
          if (this.zoomOnResults) {
            this.zoomOnResults = false
            this.fitMapToResults()
          }
        } else {
          console.error('[MapPage/updateMap] Source is NULL')
        }
      }
      if (this.map && this.searchBbox) {
        const source = this.map.getSource('source-bbox')
        if (source) {
          source.setData(this.searchBbox)
        } else {
          console.error('[MapPage/updateMap] Source is NULL')
        }
      }
    },
    fitMapToResults () {
      if (this.map && this.geoData && this.geoData.features.length > 0) {
        const bb = turf.bbox({
          type: 'FeatureCollection',
          features: this.geoData.features
        })
        this.map.fitBounds(bb, {
          padding: 50,
          maxZoom: 15,
          duration: 0
        })
      }
    },
    zoomend () {
      // Uncomment for blocking search when detail is selected (search input element is null)
      //if (this.$refs.autocomp) {
      this.bbox = this.mapBounds()
      this.fetchData(this.searchString, this.selectedType, this.bbox)
      // Update only if detail is not visible
      this.updateQuery()
      //}
    },
    dragEnd () {
      /* sw - lesser
      *  ne - higher
      */
      // Uncomment for blocking search when detail is selected (search input element is null)
      //if (this.$refs.autocomp) {
      this.bbox = this.mapBounds()
      this.fetchData(this.searchString, this.selectedType, this.bbox)
      // Update only if detail is not visible
      this.updateQuery()
      //}
    },
    clicked (map, e) {
      if (e.features.length < 1) return
      const feature = e.features[0]
      const featureProp = feature.properties
      const detail = this.places.find(value => featureProp.osm_id === value.osm_id && featureProp.osm_type === value.osm_type)
      if (detail) {
        this.selectDetail(detail)
      }
    },
    mapBounds () {
      if (this.map) {
        const b = this.map.getBounds()
        console.log(JSON.stringify([b._sw.lat, b._sw.lng, b._ne.lat, b._ne.lng]))
        return [b._sw.lat, b._sw.lng, b._ne.lat, b._ne.lng]
      }
      return null
    },
    mapZoomToCzechRepublic () {
      if (this.map) {
        this.map.fitBounds([11.830802078935022, 48.514505899476546, 19.084546340967393, 51.125759742983064], {
          duration: 500
        })
      }
    },
    selectDetail (detail) {
      this.detail = detail
      /*
      For mobile, show map when selected other category, otherwise go to list (detail)
       */
      if (this.detail.category === 'other') {
        this.emptySearchString()
        this.showMap = true
      } else {
        this.showMap = false
      }
      this.updateQuery()
      let options = {
        center: [detail.location.lon, detail.location.lat],
        duration: 1000
      }
      if (this.map) {
        if (this.map.getZoom() < 15) {
          options.zoom = 15
        }
        this.map.flyTo(options)
      }

    },
    mouseEnterFeature (e, f) {
      this.map.getCanvas().style.cursor = 'pointer'
      const coordinates = f.features[0].geometry.coordinates.slice()
      const description = f.features[0].properties.full_name

      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(f.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += f.lngLat.lng > coordinates[0] ? 360 : -360
      }
      this.showPopup(coordinates, JSON.parse(description))
    },
    mouseLeaveFeature () {
      this.map.getCanvas().style.cursor = ''
      this.hidePopup()
    },
    showPopup (coordinates, description) {
      let textValues = Object.values(description)
      let title = textValues.length > 2 ? textValues.slice(0, 3).join(', ') : textValues.join(', ')
      let subtext = textValues.length > 2 ? textValues.slice(3, -1).join(', ') : ''
      this.mapPopup.setLngLat(coordinates).setHTML(`<h5 style="font-weight: 600">${title}</h5>${subtext}`).addTo(this.map)
    },
    hidePopup () {
      this.mapPopup.remove()
    },
    geolocateError (control, positionError) {
      console.log(positionError)
    },
    geolocate (control, position) {
      console.log(
          `User position: ${position.coords.latitude}, ${position.coords.longitude}`
      )
    },
    category2icon (category) {
      return category2icon(category)
    },
    validSearchString (string) {
      return string && string.length >= 3
    },
    getResultValue (result) {
      return result.name
    },
    search (input) {
      const url = `${config.baseUrlAPI}/api/search`
      this.searchString = input

      return new Promise(resolve => {
        if (input.length < 3 || !this.map) {
          return resolve([])
        }

        axios.post(url, {
          search_string: `${input}`,
          category: `${this.selectedType}`,
          geojson: false,
          bbox: this.mapBounds()
        }).then(response => {
          if (response.status === 200) {
            const searchString = this.searchString
            this.searchResults = response.data.places
            resolve([{
              id: null,
              name: `${searchString}`
            }, ...response.data.places])
          } else {
            console.log(response.statusText)
          }
        }).catch(e => {
          console.log(e)
        })
      })
    },
    onSubmit (result) {
      console.log('[MapPage/OnSubmit] ' + JSON.stringify(result))
      // if (!this.searchResults) return
      const search_string = this.$refs.autocomp.value
      this.searchString = search_string

      let isOnlyOther = true
      if (this.searchResults) {
        for (let res of this.searchResults) {
          if (res.category !== 'other') {
            isOnlyOther = false
            break
          }
        }
      }
      this.updateQuery()
      if ((!result) && this.searchResults && this.searchResults.length > 0 && isOnlyOther) {
        this.selectDetail(this.searchResults[0])
      } else if (result && result.osm_id && result.osm_type && result.category === 'other') {
        this.selectDetail(result)
      } else {
        this.fetchData(search_string, this.selectedType, this.mapBounds())
      }
      this.searchResults = null
    },
    emptySearchString () {
      this.searchString = ''
      if (this.$refs.autocomp) {
        this.$refs.autocomp.value = ''
      }
    },
    emptySearchStringAndFetch () {
      this.emptySearchString()
      this.fetchData(this.searchString, this.selectedType, this.mapBounds())
    },
    updateQuery () {
      let searchString = this.searchString
      let searchType = this.selectedType
      const detail = this.detail

      let query = {
        st: searchType
      }
      if (this.validSearchString(searchString)) {
        query.ss = encodeURI(searchString)
      }

      if (this.detail) {
        query.i = detail.osm_id
        query.t = detail.osm_type
        // overwrite searchString na type
        query.ss = encodeURI(detail.name)
        query.st = encodeURI(detail.category)
        query.lat = detail.location.lat
        query.lon = detail.location.lon
        console.log('[url] Query: ' + JSON.stringify(query))
      }

      // We have to check if any value has changed, otherwise an error would occur.
      if (
          this.$route.query.ss !== query.ss ||
          this.$route.query.st !== query.st ||
          this.$route.query.i !== query.i ||
          this.$route.query.t !== query.t ||
          this.$route.query.lat !== query.lat ||
          this.$route.query.lon !== query.lon
      ) {
        this.$router.replace({
          name: 'MapPage',
          query: query
        })
      }
    },
    hasHistory () {
      return window.history.length > 2
    }
  }
}
</script>

<style lang="less" scoped>
@import "../assets/css/styles";

.map-h {
  height: calc(100vh - 50px);
}

.logo {
  padding-top: 15px;
  padding-bottom: 15px;
}

.navbar-h {
  height: 70px;
}

.input-h {
  height: 48px;
}

.back-btn,
.back-btn:focus,
.back-btn:hover {
  height: 25px;
  color: @color-blue-dark;
  background: none;
  box-shadow: none;
  margin-bottom: 20px;
  padding-right: 0;
  font-weight: 600;
  font-size: 14px;
}

.type-select {
  border: 1px solid @color-grey;
  border-radius: 7px;
  box-shadow: none;
  color: @color-blue-dark;
}

.p-result-panel {
  padding-left: 24px;
  padding-right: 24px;
}

.m-result-panel {
  margin-left: 24px;
  margin-right: 24px;
}

.detail-container {
  height: calc(100vh - 82px); /* - (navh + margin bottom)*/
}

.detail {
  height: calc(100vh - 82px - 45px); /* - (navh + margin bot) - backArrow */
  overflow-y: auto;
}

.loading-placeholder-category {
  height: 36px;
  width: 36px;
  margin-right: 12px;
  background: @color-green;
  border-radius: 18px;
  opacity: 0.5;
}

.loading-placeholder-text {
  width: calc(90% - 48px);
  height: 36px;
  background: @color-green;
  border-radius: 50px;
  opacity: 0.5;
}

.divider {
  opacity: 0.5;
  border-bottom: 1px solid @color-grey;
}

.switcher-btn {
  height: 50px;
  background: #A0C1B8;
  color: @color-blue-dark;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
}

.switcher-btn-text {
  margin-left: 24px;
}

.switcher-btn-icon {
  margin-right: 24px;
  height: 16px;
}

.switcher-btn:hover {
  cursor: pointer;
}

/***
! Important !
This height must be manually precomputed to enable scrolling of the result list.
It is computed as 100vh - static content (such as header, search input, and categories)
 */
.result-container {
  width: 100%;
  overflow: auto;
  // height: calc(100vh - 317px + 50px + 20px - 50px); /* + logo diff - map/list switcher */
  height: calc(100vh - 352px - 50px); /* - (computed height of static searchbox + categories) - height of mobile switch btn */
}

.error-img {
  max-width: 70%;
}

.category-r {
  margin-bottom: 8px;
}

.category-c1 {
  padding-right: 4px;
}

.category-c2 {
  padding-left: 4px;
  padding-right: 4px;
}

.category-c3 {
  padding-left: 4px;
}

.zoomout-btn {
  background-color: @color-green;
  border-radius: 6px;
  height: 40px;
  width: 180px;
  position: absolute;
  top: 16px;
  left: 16px;
  cursor: pointer;
}

.add-new-place {
    background: @color-green;
    border-radius: 5px;
    padding: 2px;
    margin: 24px auto;
    width: 50%;
}

.add-new-place a {
  color: #0C243D;
}

@media (min-width: @md) {
  /* md */
  .error-img {
    max-height: none;
  }
}

@media (min-width: @lg) {
  /* lg */
  .navbar-h {
    height: 90px;
  }

  .map-h {
    height: calc(100vh);
  }

  .logo {
    padding-top: 25px;
    padding-bottom: 25px;
  }

  .detail-container {
    height: calc(100vh - 102px) /* - (navh + margin bot)*/
  }

  .detail {
    height: calc(100vh - 102px - 45px) /* - (navh + margin bot) - backArrow */
  }

  /***
! Important !
This height must be manually precomputed to enable scrolling of the result list.
It is computed as 100vh - static content (such as header, search input, and categories)
 */
  .result-container {
    height: calc(100vh - 390px);
    margin-bottom: 20px;
  }

  .category-r {
    margin-bottom: 16px;
  }

  .category-c1 {
    padding-right: 8px;
  }

  .category-c2 {
    padding-left: 8px;
    padding-right: 8px;
  }

  .category-c3 {
    padding-left: 8px;
  }

  .zoomout-btn {
    top: calc(100% - 80px);
    left: 16px;
  }

  .add-new-place {
    background: @color-green;
    border-radius: 5px;
    padding: 3px;
    margin: 24px auto;
    width: 40%;
  }

  .add-new-place a {
    color: #0C243D;
  }
}

</style>

<style src="@/assets/css/mapbox-gl.css"/>
