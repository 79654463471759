<template>
  <div :class="isSelected ? 'root-selected' : ''"
       class="root align-items-center text-center"
       @click="$emit('onSelect', category)">
    <div class="icon d-flex">
      <img :src="category2icon(category)" alt="category" class="m-auto">
    </div>
    <h5 class="category-text" v-html="$t(`category_${this.category}`)">
    </h5>
  </div>
</template>

<script>
import category2icon from '@/utils/category2icon'

export default {
  name: 'CategoryButton',
  props: ['category', 'isSelected'],
  methods: {
    category2icon: function (category) {
      return category2icon(category)
    }
  }
}
</script>

<style lang="less" scoped>
@import "src/assets/css/styles.less";

.root {
  background-color: rgba(216, 216, 216, 0.3);
  border-radius: 6px;
  padding-top: 6px;
  padding-bottom: 6px;
  cursor: pointer;
}

.root-selected {
  background-color: @color-green;
}

.icon {
  background: @color-white;
  height: 24px;
  width: 24px;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  padding: 5px;
  border-radius: 12px;
  border: solid @color-green 1px;
}

.category-text {
  font-weight: 500;
  margin-top: 6px;
  margin-bottom: 4px;
}

</style>
